import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { OptixComponentBase } from './core/utils/base-components/optix-component-base';
import { LogUpdateService } from './core/services/service-worker/log-update.service';
import { PromptUpdateService } from './core/services/service-worker/prompt-update.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent extends OptixComponentBase implements OnInit {
  title = 'Dolphyn Hydrogen';

  constructor(private primengConfig: PrimeNGConfig, private logUpdateService: LogUpdateService, private promptUpdateService: PromptUpdateService) {
    super();
    this.logDebug(this.constructor.name, 'loaded');
  }

  public ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.logDebug(this.ngOnInit.name, 'loaded');
  }

  public reloadApp(): void {
		// Reload the page to update to the latest version.
		document.location.reload();
  }
}
