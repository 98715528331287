import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './app-config/auth.config';
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE } from '@azure/msal-angular';
import { RaygunErrorHandler } from './app-config/app.raygun.setup';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { AppInitialiserComponent } from './app-initialiser.component';
import { SharedModule } from 'primeng/api';
import { HomeModule } from './modules/home/home.module';
import { ErrorInterceptor } from './core/utils/interceptors/error-interceptor';
import { AuthInterceptor } from './core/utils/interceptors/auth-interceptor';
import { SpinnerInterceptor } from './core/utils/interceptors/spinner-interceptor';
import { CoreComponentsModule } from './core/components/core-components.module';
import { TitleStrategy } from '@angular/router';
import { AppTitleBuilder } from './core/utils/title-strategy/app-title-builder.strategy';
import { LogUpdateService } from './core/services/service-worker/log-update.service';
import { PromptUpdateService } from './core/services/service-worker/prompt-update.service';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
 export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Redirect,
  };
}

@NgModule({
  declarations: [
    AppComponent,
    AppInitialiserComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    // Main App Routing Module
    AppRoutingModule,
    // PrimeNG Shared Module
    SharedModule,
    // PWA Service Wroker
    ServiceWorkerModule.register('ngsw-worker.js', {
      // Only Enable the service worker when the app is not in local dev
      // mode, we don't want the service worker interupting local development
      // see the readme for instructions on enabling the service worker
      enabled: (environment.mode !== 'local'),
      //enabled: true,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    // Optix Core Components Module
    CoreComponentsModule,
    // Optix Home Module
    HomeModule,
    //PrimeNg Modules
    ToastModule,
    ButtonModule
  ],
  providers: [
    HttpClientModule,
    // Optix HTTP Request Interceptors
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    //{ provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    // Microsoft Azure B2C Interceptors and services
    { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
    { provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    // Raygun Error Catcher
    { provide: ErrorHandler, useClass: RaygunErrorHandler },
    { provide: TitleStrategy, useClass: AppTitleBuilder },
    // Service Worker Helpers
    LogUpdateService,
    PromptUpdateService
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
