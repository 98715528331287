<header class="flex p-2">
    <div *ngIf="sessionInfo.currentTenant" class="flex-none flex align-items-center">
        <button pButton pRipple type="button" icon="pi pi-bars" class="p-button-outlined mr-3" (click)="handleShowMenu()"></button>
    </div>

    <div class="flex-none flex align-items-center">
        <brand-logo logoHeight="48"></brand-logo>
    </div>

    <div class="flex-grow-1 flex align-items-center justify-content-center">
        <app-environment-banner></app-environment-banner>
        <p-message *ngIf="impersonationSession" severity="warn" text="Impersonation Session will end at {{impersonationSessonEndTime | date:'dd MMM yyyy HH:mm'}}" styleClass="ml-2 mr-2"></p-message>
    </div>

    <div *ngIf="sessionInfo.isAuthenticated && sessionInfo.currentTenant" class="flex-none flex align-items-center mr-3">
        <!-- <a *ngIf="tenantCount > 1" [routerLink]="['/main/tenant-selector']" routerLinkActive=BusinessObjectStatus.Active>
            <img [src]="getSafeLogo(sessionInfo.currentTenant)" alt="{{sessionInfo.currentTenant.name}}" height="48" />
        </a>
        <img *ngIf="tenantCount === 1" [src]="getSafeLogo(sessionInfo.currentTenant)" alt="{{sessionInfo.currentTenant.name}}" height="48" /> -->
        <a href="https://www.dolphynhydrogen.com/" target="_blank">
            <img [src]="getSafeLogo(sessionInfo.currentTenant)" alt="{{sessionInfo.currentTenant.name}}" height="48" />
        </a>
        <!--
        <span class="ml-2 mr-2 separator-bar"></span><span class="text-sm"></span>
        <div *ngIf="asset">
            <div *ngIf="tenantAssets.length > 1">
                <p-dropdown #assetDropdown="ngModel" [options]="tenantAssets" optionLabel="name" [(ngModel)]="asset" (onChange)="onAssetChange()" appendTo="body">
                    <ng-template pTemplate="selectedItem">
                        <h3 style="display: inline-block;">{{asset.name}}</h3><span class="ml-2 mr-2 separator-bar"></span><span class="text-sm">{{asset.timezoneModel.name}}</span>
                    </ng-template>
                    <ng-template let-assetOption pTemplate="item">
                        <h3 style="display: inline-block;">{{assetOption.name}}</h3><span class="ml-2 mr-2 separator-bar"></span><span class="text-sm">{{assetOption.timezoneModel?.name}}</span>
                    </ng-template>
                </p-dropdown>
            </div>
            <div *ngIf="tenantAssets.length < 2">
                <h3 style="display: inline-block;">{{asset.name}}</h3><span class="ml-2 mr-2 separator-bar"></span><span class="text-sm">{{asset.timezoneModel.name}}</span>
            </div>
        </div>
        -->
    </div>

    <div class="flex-none flex align-items-center" *ngIf="sessionInfo.isAuthenticated">
        <p-splitButton label="{{username}}" icon="pi pi-user" styleClass="p-button-outlined" [model]="items"></p-splitButton>
    </div>
</header>

<menu-bar [(menuVisible)]="menuVisible" [tenant]="sessionInfo.currentTenant"
    [asset]="sessionInfo.currentAsset"></menu-bar>

<p-dialog header="Accept Terms and Privacy" [(visible)]="displayTermsDialog" position="top" [modal]="true"
    [style]="{width: '50vw'}" [draggable]="false" [resizable]="false" [closable]="false">
    <p>
        Please click the links to view the policies and terms. Once all have been viewed, you may accept.
    </p>
    <p>
        <a target='_blank' href="https://www.opex-group.com/privacy-policy" class="mr-2" (click)="privacyViewed=true">Privacy Policy</a>
    </p>
    <p>
        <a target='_blank' href="/account/cookie" class="mr-2" (click)="cookiesViewed=true">Cookies Policy</a>
    </p>
    <p>
        <a target='_blank' href="/account/terms" class="mr-2" (click)="termsViewed=true">Terms of Use</a>
    </p>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-times-circle" (click)="logout()" label="Reject" styleClass="p-button-text p-button-secondary"></p-button>
        <p-button icon="pi pi-check-circle" [disabled]="!privacyViewed || !cookiesViewed || !termsViewed" (click)="acceptedTermsAndPrivacy()" label="Accept" styleClass="p-button-text p-button-success"></p-button>
    </ng-template>
</p-dialog>