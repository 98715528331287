import { Component, Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { OptixComponentBase } from '../../utils/base-components/optix-component-base';

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-spinner',
  templateUrl: './app-spinner.component.html'
})
export class AppSpinnerComponent extends OptixComponentBase {
  loadingText = "Please wait..."

  showCount: number = 0;

  constructor(private spinner: NgxSpinnerService) {
    super();
    this.logDebug(this.constructor.name, 'loaded');
  }

  public showMultiple(times: number): void {
    for(var i = 0; i < times; i++)
      this.show();
  }

  public show(): void {
    this.showCount++;
    this.logDebug(this.show.name, 'spinner show called, counter incremented', [this.showCount]);
    // Only call show on the spinner if the count is at 1
    if (this.showCount === 1) {
		  this.spinner.show('applicationSpinner');
	  }    
  }

  public hide(): void {
    if (this.showCount > 0) {
      this.showCount--;
      this.logDebug(this.hide.name, 'spinner hide called, counter decremented', [this.showCount]);
      // Hide the spinner if the count has reached zero
      if (this.showCount === 0) {
        this.spinner.hide('applicationSpinner');
      }
    }    
  }
}
