import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { BehaviorSubject, Observable, of } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { FlareStackModel } from "../../models/asset/emissions/flaring/flare-stack-model";
import { TokenModel } from "../../models/authentication/token.model";
import { TenantModel } from "../../models/global/clients/tenant-model";
import { SessionState } from "../../models/session/session-state.model";
import { AssetModel } from "../../models/tenant/location/asset-model";
import { OptixComponentBase } from "../../utils/base-components/optix-component-base";
import { AssetUserService } from "../tenant/permissions/asset-user/asset-user.service";
import { SessionIdentityService } from "./identity/session-identity.service";
import { SessionTokenService } from "./token/session-token.service";
import { AssetSettingService } from "../asset/configuration/asset-setting.service";

@Injectable({
    providedIn: 'root'
})
export class AppSessionService extends OptixComponentBase {
    
    /**
     * The current state of the session
     */
    private readonly _sessionState = new BehaviorSubject<SessionState>(new SessionState());

    /**
     * Default Constructor
     */
    constructor(private identityService: SessionIdentityService, private tokenService: SessionTokenService, private assetUserService: AssetUserService, private assetSettingService: AssetSettingService) { 
        super();
        this.logDebug(this.constructor.name, 'loaded');
    }

    /**
     * Gets the current value of the session state
     * @returns The session state current value
     */
    public getSessionState(): SessionState {
        return this._sessionState.getValue();
    }

    /**
     * The publicly accessible session state
     */
    public sessionStateObservable(): Observable<SessionState> {
        return this._sessionState.asObservable();
    }

    /**
     * Logs the user out of the application
     * @param postLogoutUri the url to direct the user to after logging out
     */
    public logout(postLogoutUri: string): void {
        // Clear the session state
        this._sessionState.next(new SessionState());
        this._sessionState.complete();
        // Log the user out of the identity provider
        this.identityService.logout(postLogoutUri);
    }

    /**
     * Initialises the session
     * @returns true when the session is initialised
     */
    public initialiseSession(): Observable<boolean> {
        let isAuthenticated = this.identityService.isAuthenticated();

        // Ensure the session state has set as initialised
        let sessionState = this.getSessionState();
        
        if (isAuthenticated && !sessionState.isSessionInialised) {
            // Set the session as authenticated and initialised
            sessionState.isAuthenticated = isAuthenticated;
            sessionState.isSessionInialised = true;
            sessionState.identityId = this.identityService.getIdentityId();
            this._sessionState.next(sessionState);
        }

        // Return that the session has been initialised
        return of(isAuthenticated);
    }

    /**
     * Re-initialises the current session with the tenant and asset properties for the route
     * @param tenants The tenants for the current user
     * @param tenant The currently selected tenant
     * @param assets The assets that the user has access to on the tenant
     * @param asset The currently selected asset
     */
    public reinitialiseSession(tenants: TenantModel[], tenant: TenantModel | undefined, assets: AssetModel[], asset: AssetModel | undefined) {
        let sessionState = this.getSessionState();
        // Update the session properties
        sessionState.tenants = tenants;
		sessionState.currentTenant = tenant;
        sessionState.assets = assets.filter(e => e.userAccessible);
        sessionState.currentAsset = asset;

		this._sessionState.next(sessionState);
    }

    /**
     * Updates the session details from the token
     * @param tokenModel The token model details
     */
    public updateSessionFromNewToken(tokenModel: TokenModel, tenant: TenantModel | undefined, asset: AssetModel | undefined) {
        this.logDebug(this.updateSessionFromNewToken.name, 'Entering session update from token');
        // Decode the token
        let jwtHelper = new JwtHelperService();
		let decodedToken = jwtHelper.decodeToken(tokenModel.token);
        // Get the properties from the token
        let userId = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];
        let email = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'];
        let firstname = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'];
        let surname = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname'];
        let hasAcceptedPrivacy = decodedToken['Opex.Optix.HasAcceptedPrivacy'];
        let userType = decodedToken['Opex.Optix.UserType'];
        let roles = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
        // Get the session
        let sessionState = this.getSessionState();
        // Update the session
        sessionState.currentTenant = tenant;
        sessionState.currentAsset = asset;
        sessionState.userId = userId;
        sessionState.email = email;
        sessionState.firstname = firstname;
        sessionState.lastname = surname;
        sessionState.hasAcceptedPrivacy = (hasAcceptedPrivacy.toLowerCase() === "true");
        sessionState.userType = userType;
        sessionState.roles = roles;
        sessionState.jsonWebToken = tokenModel.token;
        sessionState.tokenExpiry = new Date(tokenModel.expiryDate);
        sessionState.refreshToken = tokenModel.refreshToken;

        this._sessionState.next(sessionState);
        this.logDebug(this.updateSessionFromNewToken.name, 'Exiting session update from token', [sessionState]);
    }

    /**
     * Updates the session to be an impersonated session
     * @param impersonatedUserSession The impersonated user session details
     */
    public initialiseImpersonationSession(impersonatedUserSession: SessionState) {
        this.logDebug(this.initialiseImpersonationSession.name, 'initialising impersonated session', [impersonatedUserSession]);
        this._sessionState.next(impersonatedUserSession);
        this.logDebug(this.initialiseImpersonationSession.name, 'initialised impersonated session', [impersonatedUserSession]);
    }

    /**
     * Sets the available tenants in the session state
     * @param tenants The tenants that use has access to
     */
    public updateTenants(tenants: TenantModel[]): void {
        let sessionState = this.getSessionState();
        sessionState.tenants = tenants;
        this._sessionState.next(sessionState);
    }

    /**
     * Sets the selected tenant and loads the assets for the tenant
     * @param tenant The selected tenant
     */
    public selectTenant(tenant: TenantModel): Observable<AssetModel[]> {
        let sessionState = this.getSessionState();
        // Get a new token with the users permissions for the tenant
        return this.tokenService.generateAuthTokenFromApi(sessionState.identityId, tenant.tenancyName)
                    .pipe(
                        switchMap(token => {
                            this.updateSessionFromNewToken(token, tenant, undefined);
                            return this.loadTenantAssets()
                        })
                    );
    }

    /**
     * Sets the assets that the user has access to
     * @param assets the assets that the user has access to
     */
    public updateAssets(assets: AssetModel[]): void {
        let sessionState = this.getSessionState();
        sessionState.assets = assets.filter(e => e.userAccessible);
        this._sessionState.next(sessionState);
    }

    /**
     * Removes the current asset and asset list from the session
     */
    public removeAssets(): void {
        let sessionState = this.getSessionState();
        sessionState.assets = [];
        sessionState.currentAsset = undefined;
        this._sessionState.next(sessionState);
    }

    /**
     * Selects the tenant
     * @param tenant The selected tenant
     */
    public loadTenantAssets(): Observable<AssetModel[]> {
        // Load the assets for the tenant
        return this.assetUserService.getUsersAssets()
                    .pipe(
                        tap(data => this.updateAssets(data))
                    );
    }

    /**
     * Sets the selected asset
     * @param asset The selected asset
     */
     public selectAsset(asset: AssetModel, clearQueue: boolean = false): Observable<TokenModel> {
        let sessionState = this.getSessionState();        
        // Get the asset settings a new token with the users permissions for the asset
        return this.assetSettingService.getAllAssetSettings(asset.id, clearQueue)
                    .pipe(
                        switchMap((assetSettings) => {
                            asset.assetSettingModels = assetSettings;
                            return this.tokenService.generateAuthTokenFromApi(sessionState.identityId, sessionState.currentTenant == null ? '' : sessionState.currentTenant.tenancyName, asset.id)
                                        .pipe(
                                            tap(data => {
                                                this.updateSessionFromNewToken(data, sessionState.currentTenant, asset);
                                            })
                                        );
                        })
                    );
    }

    /**
     * Sets the current flare stack
     * @param currentFlareStack The flare stack selected
     */
    public setCurrentFlareStack(currentFlareStack: FlareStackModel): void {
        let sessionState = this.getSessionState();
        sessionState.currentFlareStack = currentFlareStack;
        this._sessionState.next(sessionState);
    }

    /**
     * Sets the acceptance of the terms and privacy
     */
    public setAcceptTermsAndPrivacy(): void {
        let sessionState = this.getSessionState();
        sessionState.hasAcceptedPrivacy = true;
        this._sessionState.next(sessionState);
    }
}