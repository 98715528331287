import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { TenantModel } from '../../models/global/clients/tenant-model';
import { AssetModel } from '../../models/tenant/location/asset-model';
import { UserService } from '../../services/global/clients/user.service';
import { ImpersonationService } from '../../services/session/impersonation.service';
import { AppComponentBase } from '../../utils/base-components/app-component-base';

@Component({
  templateUrl: './app-header.component.html',
  selector: 'app-header'
})
export class AppHeaderComponent extends AppComponentBase implements OnInit {

  tenantCount: number = 0;
  asset: AssetModel | undefined;
  tenantAssets: AssetModel[] = [];

  public username: string = '';
  public items: MenuItem[] = [];
  public impersonationSession: boolean = false;
  public impersonationSessonEndTime!: Date;

  @Input() menuVisible: boolean = false;
  @Output() menuVisibleChange: EventEmitter<boolean> = new EventEmitter();

  public displayTermsDialog: boolean = false;
  public privacyViewed: boolean = false;
  public cookiesViewed: boolean = false;
  public termsViewed: boolean = false;

  constructor(@Inject(DOCUMENT) private document: Document, private sanitizer: DomSanitizer, private router: Router, private userService: UserService, private impersonationService: ImpersonationService) {
    super();

    this.items = [
      { label: 'Logout', icon: 'pi pi-sign-out', command: () => { this.logout(); } }
    ];
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    let sessionSubscription = this.appSessionService.sessionStateObservable().subscribe(s => {
      this.sessionInfo = s;
      this.loadPageData();
    });
    this.sessionStateSubscription.add(sessionSubscription);
  }

  public override loadPageData(): void {
    // Get the session info
    this.tenantCount = ((this.sessionInfo.tenants) ? this.sessionInfo.tenants.length : 0);

    if (this.sessionInfo.isAuthenticated) {
      this.username = `${this.sessionInfo.firstname ?? ''} ${this.sessionInfo.lastname ?? ''}`;
      this.displayTermsDialog = (this.username.trim() !== '') && !this.sessionInfo.hasAcceptedPrivacy;
      this.impersonationSession = this.sessionInfo.originalUserSessionState !== undefined;

      // Change the username text if the user is impersonating another user
      if (this.sessionInfo.originalUserSessionState) {
        this.username = `${this.sessionInfo.originalUserSessionState.firstname ?? ''} ${this.sessionInfo.originalUserSessionState.lastname ?? ''} as ${this.sessionInfo.firstname ?? ''} ${this.sessionInfo.lastname ?? ''}`;
        this.impersonationSessonEndTime = this.sessionInfo.tokenExpiry;
        // Since the session is being impersonated, add an option to return to original session
        if (this.items.findIndex(e => e.label === 'End Impersonation') === -1) {
          this.items.push({ separator: true });
          this.items.push({ label: 'End Impersonation', icon: 'pi pi-power-off', command: () => { this.endImpersonation(); } });
        }
      }
      else {
        // Remove the end impersonation options from the menu if present
        if (this.items.length > 1) {
          this.items.splice(1, 2);
        }
      }
    }

    this.tenantAssets = this.sessionInfo.assets ?? [];
    this.asset = this.sessionInfo.currentAsset;
  }

  public handleShowMenu() {
    this.menuVisible = true;
    this.menuVisibleChange.emit(this.menuVisible);
  }

  public getSafeLogo(tenant: TenantModel): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl("data:" + tenant.logoFileType + ";base64, " + tenant.logo);
  }

  public logout() {
    this.appSessionService.logout(this.document.location.origin);
  }

  public onAssetChange() {
    this.logDebug(this.onAssetChange.name, 'asset selection changed', [this.asset]);
    if (this.asset !== undefined) {
      var currentAsset = this.appSessionService.getSessionState().currentAsset;
      var assetName = currentAsset?.name;
      assetName = assetName?.replace(' ', '%20');
      this.router.navigateByUrl(this.router.url.replace(assetName ?? '', this.asset.name));
    }
  }

  public acceptedTermsAndPrivacy(): void {
    this.logDebug(this.acceptedTermsAndPrivacy.name, 'accepting terms and privacy');
    // Accept the terms and privacy
    if (this.privacyViewed && this.cookiesViewed && this.termsViewed) {
      this.userService.acceptTermsAndPrivacy().subscribe({
        next: (response) => {
          // Update the session state with the acceptance
          this.appSessionService.setAcceptTermsAndPrivacy();
          this.displayTermsDialog = false;
        },
      });
    }
  }

  public endImpersonation(): void {
    this.logDebug(this.endImpersonation.name, 'ending impersonation');
    this.impersonationService.endImpersonationSession();
  }
}
