import { Component, Injector } from '@angular/core';
import { AppComponentBase } from 'src/app/core/utils/base-components/app-component-base';

@Component({
  selector: 'app-root',
  templateUrl: './fatal-exception.component.html',
})
export class FatalExceptionComponent extends AppComponentBase {
  constructor() {
    super();
  }
}
