import { Component, Input } from '@angular/core';

@Component({
  templateUrl: './brand.component.html',
  selector: 'brand-logo'
})
export class BrandComponent{
  
    @Input() logoHeight: string = "64";
  
    constructor() {}
}
