import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { TokenModel } from "../../models/authentication/token.model";
import { BusinessObjectStatus } from "../../models/constants/business-object-status-enum";
import { TenantModel } from "../../models/global/clients/tenant-model";
import { SessionState } from "../../models/session/session-state.model";
import { AssetModel } from "../../models/tenant/location/asset-model";
import { OptixComponentBase } from "../../utils/base-components/optix-component-base";
import { TenantService } from "../global/clients/tenant.service";
import { AssetUserService } from "../tenant/permissions/asset-user/asset-user.service";
import { AppSessionService } from "./app.session.service";
import { SessionTokenService } from "./token/session-token.service";
import { AssetSettingService } from "../asset/configuration/asset-setting.service";

@Injectable({
    providedIn: 'root'
})
export class AppSessionInitialiser extends OptixComponentBase {

    /**
     * Default constructor
     * @param appSessionService the app session service
     * @param spinner the app spinner service
     */
    constructor(private router: Router, private appSessionService: AppSessionService, private tokenService: SessionTokenService, private tenantService: TenantService,
        private assetUserService: AssetUserService, private assetSettingService: AssetSettingService) {
        super();
        this.logDebug(this.constructor.name, 'loaded');
    }

    /**
     * Initialises the app status
     */
    public init(route: ActivatedRouteSnapshot): Observable<boolean> {
		this.logDebug(this.init.name, 'started init');
		// Get the tenant and asset from the url
		var tenant = this.getTenantFromUrl(route);
        var asset = this.getAssetFromUrl(route);

		// If the session has already been initialised, no need to initialise it again
        var currentSession = this.appSessionService.getSessionState();
		if (currentSession.isSessionInialised) {
			this.logDebug(this.init.name,'session already initialised and in progress');
            // Check if the tenant or asset has changed
            if ((currentSession.currentTenant?.tenancyName === tenant) && (currentSession.currentAsset?.name === asset)) {
                this.logDebug(this.init.name, 'session already initialised - tenant and asset not changed')
                return of(true);
            }

            // If the asset has changed, just refresh the asset permissions
            if (currentSession.currentAsset?.name !== asset) {
                this.logDebug(this.init.name, 'session already initialised - asset has been changed');
                return this.initSessionForNewAsset(currentSession, route, tenant);
            }
		}

        // Initialise the full session details
        return this.initFullSession(route, tenant);
	}

    public roleGuardInit(route: ActivatedRouteSnapshot): Observable<boolean> {
        this.logDebug(this.roleGuardInit.name, 'starting role guard session init');
        // If the session has already been initialised, no need to initialise it again
        var currentSession = this.appSessionService.getSessionState();
        if (currentSession.isSessionInialised){
            this.logDebug(this.init.name, 'session already initialised and in progress');
            // Get the tenant and asset from the url
		    var tenant = this.getTenantFromUrl(route);
            var asset = this.getAssetFromUrl(route);

            // Check if the tenant or asset has changed
            if ((currentSession.currentTenant?.tenancyName === tenant) && (currentSession.currentAsset?.name === asset)) {
                this.logDebug(this.init.name, 'session already initialised - tenant and asset not changed')
                return of(true);
            }

            // If the asset has changed, just refresh the asset permissions, 
            // If there is a current asset in the session, but not found in the url, just continue as it will be the dashboard page the user is going to.
            if (currentSession.currentAsset?.name !== asset && !(currentSession.currentAsset && !asset)) {
                this.logDebug(this.init.name, 'session already initialised - asset has been changed');
                return this.initSessionForNewAsset(currentSession, route, tenant);
            }
            return of(true);
        }
        // Check if the user has gone to a specific tenant
        var tenant = this.getTenantFromUrl(route);
        // Initialise the session
        return this.initFullSession(route, tenant);
    }

    /**
     * Initialises the full session details
     */
    public initFullSession(route: ActivatedRouteSnapshot, tenant: string): Observable<boolean> {
        this.logDebug(this.initFullSession.name, 'initialising full session');
        
		let tenants: TenantModel[] = [];
		let currentTenant: TenantModel | undefined = undefined;
		let assets: AssetModel[] = [];
		let currentAsset: AssetModel | undefined = undefined;

		let isRootAdminRoute = this.isRequestedRouteForRootAdmin(route);
        // Initialise the session
		return this.appSessionService.initialiseSession().pipe(
			// Get a token for the tenant and asset
			switchMap((b) => {
                let sessionState = this.appSessionService.getSessionState();
                return this.tokenService.generateAuthTokenFromApi(sessionState.identityId, tenant)
            }),
			// Get the tenants for the user
			switchMap((token: TokenModel) => {
                this.appSessionService.updateSessionFromNewToken(token, undefined, undefined);
				return this.getTenantsForUser();
			}),
			// Validate that the use has access to the tenant found in the url
			switchMap((t) => {
                tenants = t;
				return this.validateTenantAccess(tenants, tenant, route);
			}),
			// Get the assets for the user on the tenant
			switchMap((t) => {
				currentTenant = t;
				if (t) {
                    tenant = t.name;
                    // A tenant has been found, so get a new token for the user for the tenant
                    let sessionState = this.appSessionService.getSessionState();
                    return this.tokenService.generateAuthTokenFromApi(sessionState.identityId, tenant).pipe(
                        switchMap((t) => {
                            this.appSessionService.updateSessionFromNewToken(t, currentTenant, undefined);
                            return this.assetUserService.getUsersAssets();
                        })
                    );
                }
				else 
                    return of([]);
			}),
			// Validate the user can access the asset found in the url
			switchMap((a) => {
				assets = a;
				if (currentTenant && !isRootAdminRoute)
					return this.validateAssetAccess(currentTenant, assets, route);
				else return of(undefined);
			}),
			switchMap((a) => {
                currentAsset = a;
                let sessionState = this.appSessionService.getSessionState();
				// If the asset was found, get a new token specifically for the asset
				if (currentAsset)
					return this.assetSettingService.getAllAssetSettings(currentAsset.id)
                                .pipe(
                                    switchMap((assetSettings) => {
                                        if (currentAsset)
                                            currentAsset.assetSettingModels = assetSettings;
                                        return this.tokenService.generateAuthTokenFromApi(sessionState.identityId, sessionState.currentTenant == null ? '' : sessionState.currentTenant.tenancyName, currentAsset?.id)
                                                    .pipe(
                                                        tap(data => {
                                                            this.tokenService.generateAuthTokenFromApi(sessionState.identityId, tenant, currentAsset?.id);
                                                        })
                                                    );
                                    })
                                );
				else 
                    return this.tokenService.generateAuthTokenFromApi(sessionState.identityId, tenant);
			}),
			// Re-initialise the session with the tenant and asset details
			switchMap((t) => {
                if (t.token !== '')
					this.appSessionService.updateSessionFromNewToken(t, currentTenant, currentAsset);
				this.appSessionService.reinitialiseSession(tenants, currentTenant, assets, currentAsset);
                // Redirect the user
                this.redirectUser(tenants, currentTenant, assets, currentAsset, route);
				return of(true);
			})
		);
    }

    /**
     * Initialises the full session details
     */
    public initSessionForNewAsset(sessionState: SessionState, route: ActivatedRouteSnapshot, tenant: string): Observable<boolean> {
        this.logDebug(this.initSessionForNewAsset.name, 'initialising changed asset session');

        // If there is no tenant selected, initialise a full session
        if (!sessionState.currentAsset)
            return this.initFullSession(route, tenant);

        let currentTenant = sessionState.currentTenant ?? new TenantModel('', '', '', '', '', BusinessObjectStatus.Deleted, new Date(), new Date());
        let currentAsset: AssetModel | undefined = undefined;
        // Validate access to the new asset
        return this.validateAssetAccess(currentTenant, sessionState.assets, route)
                    .pipe(
                        switchMap((newAsset) => {
                            currentAsset = newAsset;
                            let sessionState = this.appSessionService.getSessionState();
                            // If the asset was found, get a new token specifically for the asset
                            if (currentAsset) {
                                return this.assetSettingService.getAllAssetSettings(currentAsset.id)
                                            .pipe(
                                                switchMap((assetSettings) => {
                                                    if (currentAsset)
                                                        currentAsset.assetSettingModels = assetSettings;
                                                    return this.tokenService.generateAuthTokenFromApi(sessionState.identityId, sessionState.currentTenant == null ? '' : sessionState.currentTenant.tenancyName, currentAsset?.id)
                                                                .pipe(
                                                                    tap(data => {
                                                                        this.tokenService.generateAuthTokenFromApi(sessionState.identityId, tenant, currentAsset?.id);
                                                                    })
                                                                );
                                                })
                                            );
                            }
                                //return this.tokenService.generateAuthTokenFromApi(sessionState.identityId, tenant, currentAsset.id);
                            else return this.tokenService.generateAuthTokenFromApi(sessionState.identityId, tenant);
                        }),
                        // Re-initialise the session with the tenant and asset details
                        switchMap((t) => {
                            if (t.token !== '')
                                this.appSessionService.updateSessionFromNewToken(t, currentTenant, currentAsset);
                            this.appSessionService.reinitialiseSession(sessionState.tenants, currentTenant, sessionState.assets, currentAsset);
                            return of(true);
                        })
                    );
    }

    /**
     * Gets the tenants for the user
     * @returns Collection of tenants that the user has access to
     */
    public getTenantsForUser(): Observable<TenantModel[]> {
        this.logDebug(this.getTenantsForUser.name, 'started getting tenants for user');
        return this.tenantService.getUsersTenants()
                    .pipe(
                        tap((tenants) => {
                            this.logDebug(this.getTenantsForUser.name, 'setting tenants in session state');
                            this.appSessionService.updateTenants(tenants);
                    }));
    }

    /**
     * Validates the users access to the application and tenant
     */
    public validateTenantAccess(tenants: TenantModel[], currentTenant: string, route: ActivatedRouteSnapshot): Observable<TenantModel | undefined> {
        this.logDebug(this.validateTenantAccess.name, 'started validating tenant access');
        
        // If the user doesn't have any tenants, redirect them to unauthorised
        if (tenants.length === 0) {
            this.logDebug(this.validateTenantAccess.name, 'user is not authorised to access any tenants');
            return of(undefined);
        }

        let authorisedTenant = tenants.find(x => x.tenancyName.toLowerCase() === currentTenant.toLowerCase());
        if (authorisedTenant) {
            this.logDebug(this.validateTenantAccess.name, 'user authorised to access this tenant', [currentTenant]);
            return of(authorisedTenant);
        }
        
        // If the current route being requested is just to the root of the application
        if (route.url.length === 0) {
            this.logDebug(this.validateTenantAccess.name, 'application root requested');
            // there are multiple tenants, take them to the tenant selection screen since they are just accessing the root
            if (tenants.length > 1) {
                this.logDebug(this.validateTenantAccess.name, 'user has multiple tenants, present selector');
                return of(undefined);
            }
            else {
                this.logDebug(this.validateTenantAccess.name, 'user has only got access to a single tenant, set it as selected');
                authorisedTenant = tenants[0];
            }
        }
        
        return of(authorisedTenant);
    }

    /**
     * Validates the users access to the asset
     * @param assets The assets that the user has access to.
     */
    public validateAssetAccess(tenant: TenantModel, assets: AssetModel[], route: ActivatedRouteSnapshot): Observable<AssetModel | undefined> {
        this.logDebug(this.validateAssetAccess.name, 'started validating asset access');
        // If the user doesn't have access to assets, take them to the no asset associations
        if (assets.length === 0) {
            return of(undefined);
        }

        // Check if the asset is in the url
        var currentAsset = this.getAssetFromUrl(route);
        if (!currentAsset) {
            return of(undefined);
        }

        let authorisedAsset = assets.find(x => x.name.toLowerCase() === currentAsset?.toLowerCase());
        if (authorisedAsset === undefined) {
            // User is not authorised to access this tenant
            this.logDebug(this.validateAssetAccess.name, 'user is not authorised to access this asset', [currentAsset]);
        }

        return of(authorisedAsset);
    }

    public redirectUser(tenants: TenantModel[], tenant: TenantModel | undefined, assets: AssetModel[], asset: AssetModel | undefined, route: ActivatedRouteSnapshot): void {
        this.logDebug(this.redirectUser.name, 'redirecting user if required', [tenants, tenant, assets, asset, route]);
        // If there are no tenants, send to unauthorised
        if (tenants.length === 0) {
            this.router.navigate(['unauthorised']);
            return;
        }            
        // User has access to at least 1 tenant
        if (tenant === undefined) {
            // Tenant has not been found, if they just went to the root of the application and have multiple tenants, direct the user
            // to the tenant selector
            if (route.url.length === 0 && tenants.length > 1) {
                this.router.navigate(['main', 'tenant-selector']);
                return;
            }
            else {
                // The user has attempted to go to a specific tenant, and/or has access to only 1 tenant, but that is not the tenant they are attempting to
                // access
                this.router.navigate(['unauthorised']);
                return;
            }
        }

        // If the user is not attempting to get to an admin page or dashboard, check the asset access
        let routePath = this.getPathname();
        if ((routePath.indexOf('admin') === -1 && routePath.indexOf('dashboard') === -1) || route.url.length === 0) {
            // If the user has no assets, take the user to no asset associations
            if (assets.length === 0) {
                this.router.navigate(['main', tenant.tenancyName, 'noassets']);
                return;
            }
            // Get the asset from the url
            var currentAsset = this.getAssetFromUrl(route);
            // If the asset has been provided and is the same as the url, or nothing in the url, just go to the asset
            if (asset && currentAsset && asset.name.toLowerCase() == currentAsset.toLowerCase())
                return;
            if (asset && (!currentAsset || asset.name.toLowerCase() === currentAsset.toLocaleLowerCase())) {
                this.router.navigate(['main', tenant.tenancyName, asset.name, 'hydrogen', 'home']);
                return;
            }
            // If the user only has 1 asset and is the same as the url, or nothing in the url, just go to the asset
            if (assets.length === 1 && (!currentAsset || assets[0].name.toLowerCase() === currentAsset.toLocaleLowerCase())) {
                this.router.navigate(['main', tenant.tenancyName, assets[0].name, 'hydrogen', 'home']);
                return;
            }

            // If the current asset cannot be found in the url, direct the user to the asset dashboard
            if (!currentAsset) {
                this.router.navigate(['main', tenant.tenancyName, 'dashboard']);
                return;
            }
            // If the asset is in the url and not found, the user isn't allowed access
            if (!asset) {
                this.router.navigate(['unauthorised']);
                return;
            }
        }
        this.logDebug(this.redirectUser.name, 'No redirecting required');
    }

    public getPathname(): string {
        return window.location.pathname;
    }

    /**
     * Checks the url to see if the tenant has been set in it
     */
    public getTenantFromUrl(route: ActivatedRouteSnapshot): string {
        this.logDebug(this.getTenantFromUrl.name, 'started tenant url check', [route]);

        let tenant: string | null = null;

        // Check the route params initially
        tenant = route.paramMap.get('tenant');

        // If the route param doesn't exist, check the domain
        if (!tenant) {
            this.logDebug(this.getTenantFromUrl.name, 'tenant not found in route params, so checking subdomain');
            let hostsplit = window.location.hostname.split('.');
            // If there is only 1 part, ie. localhost, just return it, otherwise get the subdomain
            if (hostsplit.length === 1)
                tenant = hostsplit[0];
            else
                tenant = window.location.hostname.split('.').slice(0, -2).join('.');
        }

        // Switch the tenant name if its just localhost
        if (!tenant || environment.optixSubDomains.includes(tenant.toLowerCase())) {
            tenant = 'dolphyn';
        }

        this.logDebug(this.getTenantFromUrl.name, 'ended tenant url check', [tenant]);
        return tenant;
    }

    /**
     * Checks the url to see if the asset has been set in it
     */
     public getAssetFromUrl(route: ActivatedRouteSnapshot): string | undefined {
        this.logDebug(this.getAssetFromUrl.name, 'started asset url check', [route]);

        let asset: string | null = null;

        // Check the route params initially
        asset = route.paramMap.get('asset');

        this.logDebug(this.getAssetFromUrl.name, 'ended asset url check', [asset]);
        return asset ?? undefined;
    }

    /**
     * Checks if the requested route is for a root admin page
     */
    public isRequestedRouteForRootAdmin(route: ActivatedRouteSnapshot): boolean {
        this.logDebug(this.isRequestedRouteForRootAdmin.name, 'checking if route is for root admin', [route.pathFromRoot]);

        return route.pathFromRoot.findIndex(e => e.routeConfig?.path === 'root') > -1;
    }
}