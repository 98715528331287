import { Injectable } from "@angular/core";

export class OptixMenuItem {
    constructor(
        public name: string,
        public role: string,
        public route: string,
        public icon: string,
        public subMenu?: OptixMenuItem[],
        public isExternal: boolean = false,
        public externalUrlSettingName: string = ''
    ) { }
}

@Injectable({
    providedIn: 'root'
})
export class MenuBase {

    /**
     * Full list of the menu items that could appear.
     * Parent menu items cannot have a route, if it does, this will prevent checking and
     * adding of any child items
     */
    public fullMenu: OptixMenuItem[] = [
        new OptixMenuItem('Root Admin', '', '', 'fa-gear', [
            //new OptixMenuItem('Api Test', '', '/root/api-test', ''),
            new OptixMenuItem('Tenants', 'Root.Tenants_Read', '/root/tenants', 'fa-sitemap'),
            new OptixMenuItem('Users', 'Root.Users_Read', '/root/users', 'fa-users'),
            new OptixMenuItem('Analytics', 'Root.Analytics_Read', '/root/analytics', 'fa-magnifying-glass-chart'),
            new OptixMenuItem('Modules', 'Root.Modules_Read', '/root/modules', 'fa-cubes'),
            new OptixMenuItem('Roles', 'Root.Roles_Read', '/root/roles', 'fa-lock-open'),
            new OptixMenuItem('Applications', 'Root.Applications_Read', '/root/applications', 'fa-window-restore'),
            new OptixMenuItem('Notification Messages', 'Root.NotificationMessages_Read', '/root/notification-messages', 'fa-sticky-note'),
            new OptixMenuItem('Settings', '', '', 'fa-gears', [
                new OptixMenuItem('Settings', 'Root.Settings_Read', '/root/settings', 'fa-window-maximize'),
                new OptixMenuItem('Chart Settings', 'Root.ChartSettings_Read', '/root/chart-settings', 'fa-chart-simple'),
                new OptixMenuItem('Card Settings', 'Root.CardSettings_Read', '/root/card-settings', 'fa-file-lines')
            ]),
        ]),
        // new OptixMenuItem('Dashboard', 'App.Tenant.AssetDashboard_Read', '/main/:tenant/dashboard', 'fa-house-chimney-crack'),
        // new OptixMenuItem('emissions.AI', '', '', 'fa-globe', [
        //     new OptixMenuItem('Energy Efficiency', '', '', 'fa-seedling', [
        //         new OptixMenuItem('Home', 'App.Tenant.Asset.Emissions.EnergyEfficiency.Home_Read', '/main/:tenant/:asset/emissions/energy-efficiency/home', 'fa-tree-city'),
        //         new OptixMenuItem('e.AI Operational Report', 'App.Tenant.Asset.Emissions.EnergyEfficiency.OperationalReport_Read', '/main/:tenant/:asset/emissions/energy-efficiency/operational-report', 'fa-file-image'),
        //         new OptixMenuItem('Analysis', 'App.Tenant.Asset.Emissions.EnergyEfficiency.Analysis_Read', '/main/:tenant/:asset/emissions/energy-efficiency/analysis', 'fa-file-waveform'),
        //         new OptixMenuItem('Turbine Analysis', 'App.Tenant.Asset.Emissions.EnergyEfficiency.TurbineAnalysis_Read', '/main/:tenant/:asset/emissions/energy-efficiency/turbine-analysis', 'fa-fan'),
        //         new OptixMenuItem('Furnace Analysis', 'App.Tenant.Asset.Emissions.EnergyEfficiency.FurnaceAnalysis_Read', '/main/:tenant/:asset/emissions/energy-efficiency/furnace-analysis', 'fa-fan'),
        //         new OptixMenuItem('Steam Analysis', 'App.Tenant.Asset.Emissions.EnergyEfficiency.SteamAnalysis_Read', '/main/:tenant/:asset/emissions/energy-efficiency/steam-analysis', 'fa-water'),
        //         new OptixMenuItem('Boilers', 'App.Tenant.Asset.Emissions.EnergyEfficiency.Boilers_Read', '/main/:tenant/:asset/emissions/energy-efficiency/boilers', 'fa-water'),
        //         new OptixMenuItem('Heaters (WHRU)', 'App.Tenant.Asset.Emissions.EnergyEfficiency.Heaters_Read', '/main/:tenant/:asset/emissions/energy-efficiency/heaters', 'fa-fire-burner'),
        //         new OptixMenuItem('Waste Heat Recovery (WHR)', 'App.Tenant.Asset.Emissions.EnergyEfficiency.Whru_Read', '/main/:tenant/:asset/emissions/energy-efficiency/whr', 'fa-battery-full'),
        //         new OptixMenuItem('History', 'App.Tenant.Asset.Emissions.EnergyEfficiency.History_Read', '/main/:tenant/:asset/emissions/energy-efficiency/history', 'fa-building-columns'),
        //         new OptixMenuItem('Power Generation', 'App.Tenant.Asset.Emissions.EnergyEfficiency.PowerGeneration_Read', '/main/:tenant/:asset/emissions/energy-efficiency/power-generation', 'fa-car-battery'),
        //         new OptixMenuItem('Hot Oil', 'App.Tenant.Asset.Emissions.EnergyEfficiency.HotOil_Read', '/main/:tenant/:asset/emissions/energy-efficiency/hot-oil', 'fa-explosion'),
        //         new OptixMenuItem('Fired Heaters', 'App.Tenant.Asset.Emissions.EnergyEfficiency.FiredHeaters_Read', '/main/:tenant/:asset/emissions/energy-efficiency/fired-heaters', 'fa-fire-burner'),
        //         new OptixMenuItem('Performance Baseline [beta]', 'App.Tenant.Asset.Emissions.EnergyEfficiency.DynamicBaseline_Read', '/main/:tenant/:asset/emissions/energy-efficiency/performance-baseline', 'fa-file-contract'),
        //         new OptixMenuItem('Energy Report [beta]', 'App.Tenant.Asset.Emissions.EnergyEfficiency.EnergyReport_Read', '/main/:tenant/:asset/emissions/energy-efficiency/energy-report', 'fa-file-lines'),
        //         new OptixMenuItem('Summary', 'App.Tenant.Asset.Emissions.EnergyEfficiency.Summary_Read', '/main/:tenant/:asset/emissions/energy-efficiency/summary', 'fa-file-invoice-dollar'),
        //         new OptixMenuItem('System Status', 'App.Tenant.Asset.Emissions.EnergyEfficiency.SystemStatus_Read', '/main/:tenant/:asset/emissions/energy-efficiency/system-status', 'fa-sim-card'),
        //         new OptixMenuItem('Statistical Analysis', 'App.Tenant.Asset.Emissions.EnergyEfficiency.StatisticalAnalysis_Read', '/main/:tenant/:asset/emissions/energy-efficiency/statistical-analysis', 'fa-chart-bar'),
        //         new OptixMenuItem('Running Hours [beta]', 'App.Tenant.Asset.Emissions.EnergyEfficiency.RunningHours_Read', '/main/:tenant/:asset/emissions/energy-efficiency/running-hours', 'fa-person-running')
        //     ]),
        //     new OptixMenuItem('Flaring and Venting', '', '', 'fa-fire-flame-curved', [
        //         new OptixMenuItem('Summary', 'App.Tenant.Asset.Emissions.Flaring.Summary_Read', '/main/:tenant/:asset/emissions/flaring/summary', 'fa-fire'),
        //         new OptixMenuItem('Analysis', 'App.Tenant.Asset.Emissions.Flaring.Analysis_Read', '/main/:tenant/:asset/emissions/flaring/analysis', 'fa-fire-burner'),
        //         new OptixMenuItem('Reconciliation', 'App.Tenant.Asset.Emissions.Flaring.Reconciliation_Read', '/main/:tenant/:asset/emissions/flaring/reconciliation', 'fa-file-pen')
        //     ]),
        //     new OptixMenuItem('Methane', 'App.Tenant.Asset.Emissions.Methane.Home_Read', '/main/:tenant/:asset/emissions/methane', 'fa-fire-flame-simple')
        // ]),
        new OptixMenuItem('hydrogen.AI', '', '', 'fa-globe', [
            new OptixMenuItem('Home', 'App.Tenant.Asset.Hydrogen.Home_Read', '/main/:tenant/:asset/hydrogen/home', ''),
            new OptixMenuItem('Analysis', 'App.Tenant.Asset.Hydrogen.Analysis_Read', '/main/:tenant/:asset/hydrogen/analysis', '')
        ]),
        // new OptixMenuItem('Communications', '', '', 'fa-comments', [
        //     new OptixMenuItem('Dashboard', 'App.Tenant.Asset.Communications.Dashboard_Read', '/main/:tenant/:asset/communications/dashboard', 'fa-store'),
        //     //new OptixMenuItem('X-Observations', 'App.Tenant.Asset.Communications.XObservations_Read', '/main/:tenant/:asset/communications/observations', ''),
        //     new OptixMenuItem('X-Investigations', 'App.Tenant.Asset.Communications.XInvestigations_Read', '/main/:tenant/:asset/communications/investigations', 'fa-user-secret'),
        //     new OptixMenuItem('X-Insights', 'App.Tenant.Asset.Communications.XInsights_Read', '/main/:tenant/:asset/communications/insights', 'fa-binoculars')
        // ]),
        // new OptixMenuItem('Oil Sampling', '', '', '', [
        //     new OptixMenuItem('Reports', 'App.Tenant.Asset.OilReports.Reports_Read', '/main/:tenant/:asset/oilreports/reports', ''),
        //     new OptixMenuItem('Schedule', 'App.Tenant.Asset.OilReports.Schedules_Read', '', '', [
        //         new OptixMenuItem('Schedules', 'App.Tenant.Asset.OilReports.Schedules_Read', '/main/:tenant/:asset/oilreports/schedules', ''),
        //         new OptixMenuItem('Compliance', 'App.Tenant.Asset.OilReports.Compliance_Read', '/main/:tenant/:asset/oilreports/compliance', '')
        //     ])
        // ]),
        new OptixMenuItem('Trends', 'App.Tenant.Asset.XPAS_Read', '', 'fa-chart-line', undefined, true, 'XpasHomeUrl'),
        new OptixMenuItem('File Repository', 'App.Tenant.Asset.FileRepository_Read', '/main/:tenant/:asset/file-repository', 'fa-file'),
        new OptixMenuItem('Asset Administration', '', '', 'fa-gear', [
            //new OptixMenuItem('Users', 'App.Tenant.Asset.Users_Read', '/main/:tenant/:asset/admin/users', ''),
            //new OptixMenuItem('Modules', 'App.Tenant.Asset.Modules_Read', '/main/:tenant/:asset/admin/modules', ''),
            new OptixMenuItem('Analytics', 'App.Tenant.Asset.Analytics_Read', '/main/:tenant/:asset/admin/analytics', 'fa-magnifying-glass-chart'),
            //new OptixMenuItem('Settings', '', '', [
            //    new OptixMenuItem('Settings', 'App.Tenant.Asset.Settings_Read', '/main/:tenant/:asset/admin/settings', ''),
            //    new OptixMenuItem('Chart Settings', 'App.Tenant.Asset.ChartSettings_Read', '/main/:tenant/:asset/admin/chart-settings', ''),
            //    new OptixMenuItem('Card Settings', 'App.Tenant.Asset.CardSettings_Read', '/main/:tenant/:asset/admin/card-settings', '')
            //]),
            new OptixMenuItem('Inventory', 'App.Tenant.Asset.Systems_Read', '/main/:tenant/:asset/admin/inventory', 'fa-boxes-stacked'),
            new OptixMenuItem('Notification Messages', 'App.Tenant.Asset.Admin.Notifications_Read', '/main/:tenant/:asset/admin/notification-messages', 'fa-comments')
        ]),
        new OptixMenuItem('Tenant Adminisitration', '', '', 'fa-gear', [
            new OptixMenuItem('Assets', 'App.Tenant.Admin.Assets_Read', '/main/:tenant/admin/assets', 'fa-sitemap'),
            new OptixMenuItem('Users', 'App.Tenant.Admin.Users_Read', '/main/:tenant/admin/users', 'fa-users'),
            new OptixMenuItem('Analytics', 'App.Tenant.Admin.Analytics_Read', '/main/:tenant/admin/analytics', 'fa-magnifying-glass-chart'),
            new OptixMenuItem('Tenant Settings', '', '', 'fa-gears', [
                new OptixMenuItem('Settings', 'App.Tenant.Admin.Settings_Read', '/main/:tenant/admin/tenant-settings', 'fa-window-maximize'),
                new OptixMenuItem('Chart Settings', 'App.Tenant.Admin.ChartSettings_Read', '/main/:tenant/admin/chart-settings', 'fa-chart-simple'),
                new OptixMenuItem('Card Settings', 'App.Tenant.Admin.CardSettings_Read', '/main/:tenant/admin/card-settings', 'fa-file-lines')
            ])
        ]),
        new OptixMenuItem('Support', '', '/main/support', 'fa-message')
    ]

    constructor() { }
}