export const environment = {
	production: true,
	mode: 'prod',
	optixApi: {
		baseUrl: 'https://api.dolphynhydrogen.ai/',
		applicationId: '00000000-0000-0000-0000-000000000001',
		applicationSecret: 'eaf599a6f17645d7a6d5e45ce2342fa9b0867bbb202c4953aec95a38a04e980f830f116538ca48c2b488a3e4fffe2f94'
	},
	raygunKey: 'sXGvWiHBqGyrNppBBf4Lg',
	b2c: {
		clientId: '6bd4c536-4111-466a-8ae0-86674af821d6',
		userFlow: 'b2c_1_si',
		signInAuthority: 'https://dolphynhydrogenai.b2clogin.com/dolphynhydrogenai.onmicrosoft.com/b2c_1_si',
		authorityDomain: 'dolphynhydrogenai.b2clogin.com'
	},
	optixSubDomains: ['', 'app'],
	analytics: {
		baseUrl: 'https://opex-prod-analytics-api.azurewebsites.net/',
		applicationId: '00000000-0000-0000-0000-000000000004',
		apiKey: 'd1e6269092ae4ffa91c5e9b18d4eb4140286c8de40e345b280155a95796c3b4e'
	}
};
