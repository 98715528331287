import { Injectable, Injector } from "@angular/core";
import { map, Observable } from "rxjs";
import { AssetDataTimeModel } from "src/app/core/models/asset/configuration/asset-data-time.model";
import { AssetSettingModel } from "src/app/core/models/asset/configuration/asset-setting-model";
import { OptixSettingModel } from "src/app/core/models/global/configuration/optix-setting-model";
import { ServiceBase } from "../../service-base";

@Injectable({
    providedIn: 'root'
})
export class AssetSettingService extends ServiceBase {

    constructor(injector: Injector) {
        super(injector);
    }

    /**
     * Saves the new asset setting.
    */
    public createAssetSettingByAssetId(assetId: string, assetSettingModel: AssetSettingModel): Observable<any> {
        return this.httpQueue.post(`${this.baseUrl}v1/AssetSetting/CreateAssetSettingWithAssetId/${assetId}`, assetSettingModel);
    }

    /**
     * Gets all asset settings
     * @returns Observable with the asset settings.
     */
    public getAssetSettingsByAssetId(assetId: string): Observable<AssetSettingModel[]> {
        return this.httpQueue.get<AssetSettingModel[]>(`${this.baseUrl}v1/AssetSetting/GetSettingsByAssetId/${assetId}`);
    }

    /**
     * Gets all asset settings
     * @returns Observable with the asset settings.
     */
    public getAllAssetSettings(assetId: string, clearQueue: boolean = false): Observable<AssetSettingModel[]> {
        return this.httpQueue.get<AssetSettingModel[]>(`${this.baseUrl}v1/AssetSetting/GetAllAssetSettings/${assetId}`, true, clearQueue);
    }

    /**
     * Gets the customised setting of the asset.
     * @param settingName The name of the setting to get.
     * @returns Observable with the setting details.
     */
    public getAssetSettingByName(settingName: string): Observable<OptixSettingModel> {
        return this.httpQueue.get<OptixSettingModel>(`${this.baseUrl}v1/AssetSetting/GetAssetSettingByName/${settingName}`);
    }

    /**
     * Updates the asset settings.
     * @param assetId The id of the asset that the setting belongs to.
     * @param assetSetting The new setting details.
     */
    public updateAssetSettingByAssetId(assetId: string, assetSetting: AssetSettingModel) {
        return this.httpQueue.post<AssetSettingModel>(`${this.baseUrl}v1/AssetSetting/UpdateAssetSettingByAssetId/${assetId}`, assetSetting);
    }

    /**
     * Deletes the asset setting.
     * @returns Observable with the deleted asset setting details.
     */
    public deleteAssetSettingByAssetId(assetId: string, assetSetting: AssetSettingModel): Observable<AssetSettingModel> {
        return this.httpQueue.delete<AssetSettingModel>(`${this.baseUrl}v1/AssetSetting/DeleteAssetSettingByAssetId/${assetId}/${assetSetting.id}`);
    }

    /**
     * Restores the asset setting.
     * @returns Observable with the restored asset setting details.
     */
    public restoreAssetSettingByAssetId(assetId: string, assetSetting: AssetSettingModel): Observable<AssetSettingModel> {
        return this.httpQueue.put<AssetSettingModel>(`${this.baseUrl}v1/AssetSetting/RestoreAssetSettingByAssetId/${assetId}/${assetSetting.id}`, '');
    }

    /**
     * Gets the asset latest data time.
     * @returns Observable with the asset data time.
     */
    public getAssetDataTime(): Observable<AssetDataTimeModel> {
        return this.httpQueue.get<AssetDataTimeModel>(`${this.baseUrl}v1/AssetSetting/GetAssetDataTime`)
                    .pipe(
                        map((data: AssetDataTimeModel) => {
                            if (data.assetDataTime)
                                data.assetDataTime = new Date(data.assetDataTime);
                            if (data.earliestAssetDataTime)
                                data.earliestAssetDataTime = new Date(data.earliestAssetDataTime);
                            return data;
                        })
                    );
    }
}