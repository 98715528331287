export class StartAnalyticsPageViewModel {
	public sessionId: string | undefined;
	public pageTitle: string = '';
	public pageData: string = '';
	public previousPageViewId: string | undefined;
    public username: string = '';
    public tenant: string = 'root';

    constructor() {}
}

export class StartAnalyticsPageViewResponseModel {
    public sessionId: string = '';
    public pageViewId: string = '';

    constructor() {}
}