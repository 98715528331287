// @ts-ignore
import * as rg4js from 'raygun4js';
import { ErrorHandler } from '@angular/core';
import { environment } from 'src/environments/environment';

rg4js('apiKey', environment.raygunKey);
rg4js('setVersion', require('../../../package.json').version);
rg4js('enableCrashReporting', true);
rg4js('logContentsOfXhrCalls', true);

export class RaygunErrorHandler implements ErrorHandler {
  handleError(e: any) {
    rg4js('send', {
      error: e, 
    });
  }
}