import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AppSpinnerComponent } from '../../components/app-spinner/app-spinner.component';
import { AppSessionService } from '../../services/session/app.session.service';
import { ImpersonationService } from '../../services/session/impersonation.service';
import { SessionTokenService } from '../../services/session/token/session-token.service';
import Swal from 'sweetalert2';
import { DOCUMENT } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

    constructor(private appSessionService: AppSessionService, private tokenService: SessionTokenService, private impersonationService: ImpersonationService, 
        private spinner: AppSpinnerComponent, @Inject(DOCUMENT) private document: Document) { }
  
    /**
     * The HTTP Interceptor
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        // If the request is to the authentication endpoint, skip adding the header
        if (req.url.includes('auth'))
            return next.handle(req);

        // Get the token and add it to the request
        let session = this.appSessionService.getSessionState();
        if (session.tokenExpiry && session.tokenExpiry < new Date()) {
            // If the session has been impersonated, end the session since it has expired
            if (session.originalUserSessionState) {
                this.impersonationService.endImpersonationSession();
                return EMPTY;
            }
            else {
                this.spinner.show();
                return this.tokenService.refreshAuthToken(session.jsonWebToken ?? '', session.refreshToken ?? '')
                            .pipe(
                                catchError(err => {
                                    this.spinner.hide();
                                    console.error('AuthInterceptor-Internal API Server Error Occurred.', err);
                                    Swal.fire('Error', "Your session could not be authorised, please log back in again.", 'error', )
                                        .then(() => {
                                            this.appSessionService.logout(this.document.location.origin);
                                        });
                                    return throwError(() => { return err; });
                                }),
                                switchMap(newToken => {
                                    this.spinner.hide();
                                    this.appSessionService.updateSessionFromNewToken(newToken, session.currentTenant, session.currentAsset);
                                    return next.handle(this.addToken(req, newToken.token));
                                })
                            );
            }
        }
            

        return next.handle(this.addToken(req, session.jsonWebToken));
    }
  
    /**
     * Adds the bearer token header to the request
     * @param req The HTTP request being made.
     * @param token The token to be added to the header.
     * @returns The HTTP request with the header set.
     */
    private addToken(req: HttpRequest<any>, token?: string): HttpRequest<any> {
        return req.clone({ setHeaders: { Authorization: 'Bearer ' + token }});
    }
}