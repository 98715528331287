<div class="container">
    <div class="pull-out content view slide-up visible">
        <h1><strong>TERMS OF USE</strong></h1>
        <p><strong>LEGAL INFORMATION</strong></p>
        <p>Operational Excellence (OPEX) Group Limited (“OPEX Group”) is registered in Scotland. Registration Number
            SC<span>382192</span> Registered Address: OPEX Digital Collaboration Centre, 18 Carden Place, Aberdeen, AB10 1UQ </p>
        <p><strong>ACCESS</strong></p>
        <p>Access to this website is permitted on a temporary basis and we reserve the right to withdraw or amend the
            service we provide on the site without notice.</p>
        <p><strong>COPYRIGHT</strong></p>
        <p>This website is protected by copyright. Permission is given for the downloading and temporary storage of one
            or more of these pages for the purpose of viewing on a personal computer or monitor. The reproduction,
            permanent storage, or retransmission of the contents of this website is prohibited without the prior written
            consent of OPEX Group. For permission to reproduce any of the contents of this website please contact us -
            <a href="mailto:ERMOPEXinfo@erm.com">ERMOPEXinfo@erm.com</a></p>
        <p>Unless otherwise stated all contents of this website are copyright of OPEX Group.</p>
        <p><strong>ACCESSIBILITY </strong></p>
        <p>OPEX Group is committed to providing a website that is accessible to the widest possible audience. We
            actively work to ensure that this website is accessible and have taken great care to ensure that it meets
            standard accessibility requirements. In doing so, we believe this site meets the WCAG 2.0 AA guidelines laid
            down by the World Wide Web Consortium (W3C). If you have any problem accessing any part of this site or
            would like further information please contact us - <a
                href="mailto:ERMOPEXinfo@erm.com">ERMOPEXinfo@erm.com</a></p>
        <p><strong>DISCLAIMER</strong></p>
        <p>OPEX Group will use reasonable care to ensure that information is accurate at the time it is added to this
            site. Please note, however, that OPEX Group cannot guarantee the information is accurate and it shall not be
            liable for any losses or damage that anyone may suffer as a result of relying on this information. The
            information may be changed by OPEX Group at any time. </p>
        <p>OPEX Group assumes no responsibility for the contents of any other websites to which this website has links.
        </p>
        <p>This website is not guaranteed to be free from any so-called computer viruses and it is strongly recommended
            that you check for such viruses before down-loading it to your computer equipment.</p>
        <p><strong>LINKS</strong></p>
        <p>Any links to third party websites are provided solely for the purpose of your convenience. Such websites are
            operated and controlled by third parties and their inclusion does not imply any endorsement or approval by
            OPEX Group of the materials on such websites.</p>
        <p><strong>CHANGES</strong></p>
        <p>OPEX Group reserves the right to amend or replace the Terms of Use at any time. If we make any substantial
            changes, we will notify you by posting a prominent notice on the site.</p>
        <p><strong>JURISDICTION</strong></p>
        <p>The Terms and Conditions shall be governed by and construed in accordance with the Laws of Scotland and any
            disputes that may arise will be subject to the jurisdiction of the Scottish courts.</p>
        <p><strong>ENQUIRIES</strong></p>
        <p>For all enquiries please contact OPEX Group – <a href="mailto:ERMOPEXinfo@erm.com">ERMOPEXinfo@erm.com</a>
        </p>
    </div><!-- / pull-out -->
</div>