import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";
import { CreateTenantModel } from "src/app/core/models/global/clients/create-tenant-model";
import { TenantModel } from "src/app/core/models/global/clients/tenant-model";
import { ServiceBase } from "../../service-base";

@Injectable({
    providedIn: 'root'
})
export class TenantService extends ServiceBase {

    constructor(injector: Injector) {
        super(injector);
    }

    /**
     * Gets all tenants
     * @returns Observable wit the tenats.
     */
    public getTenants(): Observable<TenantModel[]> {
        return this.httpQueue.get<TenantModel[]>(`${this.baseUrl}v1/tenant/gettenants`);
    }

    /**
     * Gets the tenant
     * @param id The id of the tenant to get
     * @returns Observable containing the tenant.
     */
    public getTenant(id: string): Observable<TenantModel> {
        return this.httpQueue.get<TenantModel>(`${this.baseUrl}v1/tenant/gettenant/${id}`);
    }

    /**
     * Gets the tenants for the current user.
     * @returns Observable with the tenants for the user.
     */
    public getUsersTenants(): Observable<TenantModel[]> {
        return this.httpQueue.get<TenantModel[]>(`${this.baseUrl}v1/tenant/gettenantsforuser`);
    }

    /**
     * Creates the new tenant.
     * @param newTenant The new tenant details.
     * @returns Observable with the new tenant.
     */
    public createTenant(newTenant: CreateTenantModel): Observable<TenantModel> {
        let formData = new FormData();
        formData.append('name', newTenant.name);
        formData.append('tenancyName', newTenant.tenancyName);
        formData.append('logo', newTenant.logo);

        return this.httpQueue.post<TenantModel>(`${this.baseUrl}v1/tenant/createtenant`, formData);
    }
    
    /**
     * Updates the tenant.
     * @param tenant The updated tenant details.
     * @returns Observable with the updated tenant.
     */
     public updateTenant(tenant: TenantModel): Observable<TenantModel> {
        let formData = new FormData();
        formData.append('id', tenant.id);
        formData.append('name', tenant.name);
        formData.append('tenancyName', tenant.tenancyName);
        formData.append('status', tenant.status.toString());
        // If there is a new logo, set it
        if (tenant.logoFile) {
            formData.append('logoFile', tenant.logoFile);
        }

        return this.httpQueue.post<TenantModel>(`${this.baseUrl}v1/tenant/updatetenant`, formData);
    }

    /**
     * Deletes the tenant.
     * @param tenant The tenant to be deleted.
     * @returns Observable with the deleted tenant details.
     */
    public deleteTenant(tenant: TenantModel): Observable<TenantModel> {
        return this.httpQueue.delete<TenantModel>(`${this.baseUrl}v1/tenant/deletetenant/${tenant.id}`);
    }

    /**
     * Restores the tenant.
     * @param tenant The tenant to be restored.
     * @returns Observable with the restored tenant details.
     */
     public restoreTenant(tenant: TenantModel): Observable<TenantModel> {
        return this.httpQueue.put<TenantModel>(`${this.baseUrl}v1/tenant/restoretenant/${tenant.id}`, '');
    }
}