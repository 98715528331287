import { inject, Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { lastValueFrom, map, Observable } from "rxjs";
import { SessionState } from "../../models/session/session-state.model";
import { ServiceBase } from "../service-base";
import { AppSessionService } from "./app.session.service";

@Injectable({
    providedIn: 'root'
})
export class ImpersonationService extends ServiceBase {

    private appSessionService: AppSessionService;
    private router: Router;

    constructor(injector: Injector) {
        super(injector);
        // Initialise the services
        this.appSessionService = inject(AppSessionService);
        this.router = inject(Router);
    }

    /**
     * Initialises the user impersonation session
     * @param userId The Id of the user to be impersonated
     */
    public async startImpersonationSession(userId: string, tenantId: string): Promise<void> {
        this.logDebug(this.startImpersonationSession.name, 'starting impersonation', [userId, tenantId]);
        // Get the current session
        let currentSession = {...this.appSessionService.getSessionState()};
        // Create a new session for the impersonated user
        let impersonatedUserSession = await lastValueFrom(this.createSessionStateForImpersonatedUser(userId, tenantId));
        // Update the current session with the impersonated user details
        impersonatedUserSession.isAuthenticated = true;
        impersonatedUserSession.isSessionInialised = true;
        impersonatedUserSession.hasAcceptedPrivacy = true;
        impersonatedUserSession.refreshToken = '';
        impersonatedUserSession.impersonationAdminUrl = this.router.url;
        impersonatedUserSession.originalUserSessionState = currentSession;
        // Update the session
        this.appSessionService.initialiseImpersonationSession(impersonatedUserSession);
        // Start a timer to end the session
        let currentTime = new Date();
        let sessionDuration = impersonatedUserSession.tokenExpiry.getTime() - currentTime.getTime();
        setTimeout(async () => { await this.endImpersonationSession(); }, sessionDuration);
        // Navigate the user to the dashboard
        if (impersonatedUserSession.assets.length === 0) {
			this.router.navigate(['main', impersonatedUserSession.currentTenant?.tenancyName, 'noassets']);
		}
        else {
            this.router.navigate(['main', impersonatedUserSession.currentTenant?.tenancyName, 'dashboard']);
        }
    }

    /**
     * Ends the impersonation session
     */
    public async endImpersonationSession(): Promise<void> {
        this.logDebug(this.endImpersonationSession.name, 'ending impersonation');
        // Get the current session
        let currentSession = {...this.appSessionService.getSessionState()};
        // Replace the impersonated session details with the original user session details
        if (currentSession.originalUserSessionState) {
            // Reset the session state back to the original
            this.appSessionService.initialiseImpersonationSession(currentSession.originalUserSessionState);
            // Redirect the user back to the admin screen they started from
            this.router.navigate([currentSession.impersonationAdminUrl]);
        }
        else
            this.appSessionService.logout('');        
    }

    /**
     * Creates the session details for the impersonated user
     * @param userId The id of the user being impersonated
     * @param tenantId The id if the tenant to impersonate the user in
     */
    public createSessionStateForImpersonatedUser(userId: string, tenantId: string): Observable<SessionState> {
        this.logDebug(this.createSessionStateForImpersonatedUser.name, 'creating impersonated user session details', [userId, tenantId]);
        // Get the session state
        return this.httpClient.post<SessionState>(`${this.baseUrl}v1/Impersonation/ImpersonateUser/${userId}/${tenantId}`, '')
                    .pipe(
                        map((session: SessionState) => {
                            if (session.tokenExpiry)
                                session.tokenExpiry = new Date(session.tokenExpiry);
                            
                            return session;
                        })
                    );
    }
}