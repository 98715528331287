import { HttpClient } from "@angular/common/http";
import { inject, Injector } from "@angular/core";
import { environment } from "src/environments/environment";
import { OptixComponentBase } from "../utils/base-components/optix-component-base";
import { HttpQueue } from "./http-queue.service";

export abstract class ServiceBase extends OptixComponentBase {

    protected httpClient: HttpClient;
    protected httpQueue: HttpQueue;
    protected baseUrl!: string;

    constructor(_injector: Injector) { 
        super();

        this.httpClient = _injector.get(HttpClient);
        this.httpQueue = inject(HttpQueue);
        this.baseUrl = environment.optixApi.baseUrl;
    }
}