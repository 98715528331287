import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { UserModel } from 'src/app/core/models/global/clients/user-model';
import { ServiceBase } from '../../service-base';

@Injectable({
	providedIn: 'root',
})
export class UserService extends ServiceBase {
	constructor(injector: Injector) {
		super(injector);
	}

	/**
	 * Creates the new user.
	 * @param newUser The new user details.
	 * @returns Observable with the new user.
	 */
	public createUser(newUser: UserModel): Observable<UserModel> {
		return this.httpQueue.post<UserModel>(`${this.baseUrl}v1/User/CreateUser`, newUser);
	}

	/**
	 * Creates the new user.
	 * @param tenantId The tenant id
	 * @param newUser The new user details.
	 * @returns Observable with the new user.
	 */
	public createUserByTenantId(tenantId:string, newUser: UserModel): Observable<UserModel> {
		return this.httpQueue.post<UserModel>(`${this.baseUrl}v1/User/CreateUserByTenantId/${tenantId}`, newUser);
	}

	/**
	 * Gets the user based on the email address.
	 * @param newUser The new user details
	 */
	public getUserByEmail(userModel: UserModel): Observable<UserModel> {
		return this.httpQueue.post<UserModel>(`${this.baseUrl}v1/User/GetUserByEmail`, userModel);
	}

	/**
	 * Updates the user.
	 * @param user The updated user details.
	 * @returns Observable with the updated user.
	 */
	public updateUser(user: UserModel): Observable<UserModel> {
		return this.httpQueue.post<UserModel>(`${this.baseUrl}v1/User/UpdateUser`, user);
	}

	/**
	 * Updates the user.
	 * @param tenantId The tenant id.
	 * @param user The updated user details.
	 * @returns Observable with the updated user.
	 */
	public updateUserByTenantId(tenantId:string, user: UserModel): Observable<UserModel> {
		return this.httpQueue.post<UserModel>(`${this.baseUrl}v1/User/UpdateUserByTenantId/${tenantId}`, user);
	}

    /**
     * Deletes the user.
     * @param user The user to be deleted.
     * @returns Observable with the deleted user details.
     */
    public deleteUser(user: UserModel): Observable<UserModel> {
        return this.httpQueue.delete<UserModel>(`${this.baseUrl}v1/User/DeleteUser/${user.id}`);
    }

    /**
     * Restores the user.
     * @param user The user to be restored.
     * @returns Observable with the restored user details.
     */
     public restoreUser(user: UserModel): Observable<UserModel> {
        return this.httpQueue.put<UserModel>(`${this.baseUrl}v1/User/RestoreUser/${user.id}`, '');
    }

    /**
     * Resets the user's password.
     * @param user The user to have the password reset.
     * @returns Observable with the updated user details.
     */
     public resetUserPassword(user: UserModel): Observable<UserModel> {
        return this.httpQueue.put<UserModel>(`${this.baseUrl}v1/User/ResetUserPassword/${user.id}`, '');
    }

    /**
     * Accepts the terms and privacy.
     * @returns Observable with the updated user details.
     */
    public acceptTermsAndPrivacy(): Observable<UserModel> {
        return this.httpQueue.put<UserModel>(`${this.baseUrl}v1/User/AcceptTermsAndPrivacy`, '');
    }
}
