export enum RolePagePermissions {
    None = "None",
    Read = "Read",
    Create = "Create",
    Edit = "Edit",
    Delete = "Delete",
    Approve = "Approve",
    SelfApprove = "SelfApprove",
    ViewAllAssetsOnDashboard = "ViewAllAssetsOnDashboard",
    ChartExport = "ChartExport"
}