import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { AppSessionService } from '../../services/session/app.session.service';

@Injectable()
export class AppTitleBuilder extends TitleStrategy {
	
    constructor(private title: Title, private appSessionService: AppSessionService) {
		// inject the title service
		super();
	}

	updateTitle(snapshot: RouterStateSnapshot): void {
		const title = this.buildTitle(snapshot); // build the current route title
		if (title) {
            // Get the current tenant and asset
            var sessionInfo = this.appSessionService.getSessionState();
            var tenantName = sessionInfo.currentTenant?.name;
            //var assetName = sessionInfo.currentAsset?.name;
            // Create the app title
            var appTitle = `Optix`;
            if (tenantName) appTitle = `${appTitle} | ${tenantName}`;
            //if (assetName) appTitle = `${appTitle} | ${assetName}`;

			this.title.setTitle(`${appTitle} | ${title}`); // set the app prefix with the current title.
		}
	}
}
