import { Injectable, Injector } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { lastValueFrom, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AppSessionInitialiser } from '../../services/session/app.session.initialiser';
import { AppComponentBase } from '../base-components/app-component-base';

@Injectable({
    providedIn: 'root'
})
export class RoleGuard extends AppComponentBase implements CanActivate, CanActivateChild {
    
    constructor(private appInitialiser: AppSessionInitialiser, private router: Router) { 
        super();
        this.logDebug(this.constructor.name, 'loaded');
    }

    /**
     * Checks if the token has permissions to activate the route
     * @param route The route being requested
     * @param state The route state
     * @returns true if the route can be activated
     */
    private canActivateInternal(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        this.logDebug(this.canActivateInternal.name, 'ensuring session is initialised');
        this.spinner.show();
        return this.appInitialiser.roleGuardInit(route)
            .pipe(
                tap(b => {
                    this.logDebug(this.canActivateInternal.name, 'checking route permissions');
                    this.spinner.hide();
                    // If the route hasn't any role data, nothing to check
                    if (!route.data['role'])
                        return of(true);
            
                    // Check the roles match the role required for the route
                    let routeRoles = route.data['role'];
                    // Get the users current roles
                    let session = this.appSessionService.getSessionState();
                    if (session.roles.includes(routeRoles))
                        return of(true);
                    // User doesn't have permission to access the route, send them to unauthorised
                    this.router.navigate(['unauthorised']);
                    return of(false);
                }),
                catchError(e => {
                    if (e.status === 403) {
                      // Request was not allowed.
                        console.error('RoleGuard-Forbidden request.', e);
                        Swal.fire('Forbidden', "You are not permitted to perform this action.  Please contact your administrator if you think you should have access.", 'error');
                    } else {
                        // Request was not authorised.
                        console.error('RoleGuard-Internal API Server Error Occurred.', e);
                        Swal.fire('Error', "We're sorry an unexpected error occurred and your request could not be processed.  The error has been recorded and the team are on it.", 'error');
                    }
                    this.spinner.hide();
                    return of(false);
                })
            );
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivateInternal(route, state).pipe(
            map(r => {
                this.spinner.hide();
                return r;
            }));
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivateInternal(route, state).pipe(
			map((r) => {
				this.spinner.hide();
				return r;
			})
		);
    }
}