import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { MessageService } from 'primeng/api';
import { filter } from 'rxjs';

@Injectable()
export class PromptUpdateService {
	constructor(private swUpdate: SwUpdate, private messageService: MessageService) {
        this.promptForVersionUpdates();
	}

	protected promptForVersionUpdates(): void {
		// Subscribe to when a new version is available
		this.swUpdate.versionUpdates
			.pipe(
				filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY')
			)
			.subscribe((evt) => {
				// Display prompt to reload
				this.messageService.add({
					severity: 'info',
					summary: 'App Update',
					detail: 'The app update has been installed and is ready for use.  Please reload the app to get the latest version.',
					sticky: true,
					key: 'UpdateNow'
				});
			});
	}
}
